import LastMessageImage from "../assets/LastMessage.png";

export default function EndMessage () {
    return (
        <div style={{ maxWidth: "1440px" }}>
            <div className="LastMessage" style={{ display: "flex", width: "100%", maxWidth: "1440px", alignItems: "center", backgroundColor: "#333333", paddingTop: "20px", paddingBottom: "20px"}}>
                <div style={{ maxWidth: "720px" }}>
                    <img src={LastMessageImage} alt="" style={{ width: "100%" }} />
                </div>
                <div style={{ maxWidth: "720px", width: "100%", backgroundColor: "#333333"}}>
                    <div 
                        style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "720px", width: "100%", textAlign: "center", verticalAlign: "middle", color: "#FFFFFF" }}
                    >
                        <p style={{ maxWidth: "720px", width: "100%", verticalAlign: "middle" }}>
                            自分なりに頑張ってきたあなたへ
                            <br />
                            <br />
                            一人では書くことができない目標設定、
                            <br />
                            自分では見えていなかった理想のゴールを
                            <br />
                            一緒に達成していきましょう！
                            <br />
                            <br />
                            そして、一度きりの人生。
                            <br />
                            「英語で可能性を解放しましょう！」
                            <br />
                            <br />
                            ≪申し込み・無料相談ご希望の方≫
                            <br />
                            下記公式LINEより、
                            <br />
                            【申し込み】or【無料相談】とお送りください。
                        </p>
                        <a 
                            href="https://line.me/R/ti/p/@453ltrvn"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button
                                style={{ marginTop: "20px", maxWidth: "190px", width: "100%", background: "linear-gradient(to right, #013965, #FFFFFF)", color: "#FFFFFF", fontWeight: "600", border: "none", cursor: "pointer", paddingTop: "10px", paddingBottom: "10px" }}
                            >
                                公式LINE
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};