import WhyCoachingImage1 from "../../../assets/why_1.png";
import WhyCoachingImage2 from "../../../assets/why_2.png";

export default function WhyCoachingSmartphone () {
    return (
        <>
            <div style={{ marginBottom: "100px", width: "100%", maxWidth: "650px" }}>
                <div style={{ marginBottom: "50px", width: "100%", maxWidth: "650px"}}>
                    <p style={{ marginTop: "0px", marginBottom: "15px", fontSize: "40px", fontFamily: "游ゴシック体", fontWeight: "800", lineHeight: "40px", textAlign: "center" }}>
                        Why Coaching ?
                    </p>
                    <p style={{marginTop: "0px", marginBottom: "0px", fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        コーチングを始めたきっかけ
                    </p>
                </div>
                <div style={{ marginBottom: "10px", display: "flex", width: "100%", maxWidth: "955px", justifyContent: "space-around" }}>
                    <div style={{ fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        <p>私の人生が英語によって変わったからです。</p>
                        <p>
                            過去の私は、大荒れの幼少期を過ごし、
                            <br />
                            大学受験にも失敗、浪人したのにFラン大学。
                            <br />
                            口癖は、「しんどい・疲れた」。
                            <br />
                            そんなハードモードな人生を生きていました。
                        </p>
                        <p>
                            転機は、大学1年生。周りを見渡した時に、
                            <br />
                            「このままじゃ人生が終わる」と確信し、
                            <br />
                            「何かを変えないとやばい」と直感的に感じた
                            <br />
                            のがきっかけとなり本気で英語学習を
                            <br />
                            始めました。
                        </p>
                        <p>
                            元々は、英語が話せる人に憧れていたのと、
                            <br />
                            英語が話せると自分に自信が持てるだろうなぁ
                            <br />
                            という安直な気持ちでした。
                            <br />
                            ただ、ここで人生を変えると覚悟を決め
                            <br />
                            必死に勉強しました。
                        </p>
                        <p>
                            英語力は少しずつステップアップし、
                            <br />
                            力をつけていきました。当時、TOEICスコアは
                            <br />
                            405点だったのが今では920点。英語コーチとして
                            <br />
                            独立できるレベルにまでなりました。
                        </p>
                        <p>
                            ただ、単に英語が話せるようになっただけ
                            <br />
                            ではなく英語によって私の生きる世界は広がり、
                            <br />
                            人生までもが変わりました。
                        </p>
                    </div>
                </div>
                <div style={{ display: "flex", width: "100%", maxWidth: "650px", justifyContent: "center" }}>
                    <img src={WhyCoachingImage1} alt="" style={{ width: "100%" }}/>
                </div>
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center" }}>
                    <div style={{maxWidth: "320px", fontSize: "14px", fontFamily: "游ゴシック体" }}>
                        <p style={{ marginTop: "0px", marginBottom: "0px", textAlign: "center" }}>【英語が話せるようになった後の自分】</p>
                        <ul>
                            <li>
                                自信が持てなかったところから、
                                <br />
                                積み上げによって得られた成功体験が、
                                <br />
                                自分は何でもできるという根拠のある
                                <br />
                                自信がついた。
                            </li>
                            <li>
                                失敗を失敗と思わなくなり、
                                <br />
                                何にでも挑戦することができるように
                                <br />
                                なった。
                            </li>
                            <li>
                                住む世界が小さな島国から地球に広がり、
                                <br />
                                生き方、マインド面までもが変わった。
                            </li>
                            <li>
                                英語を活かした仕事で生きていくのに
                                <br />
                                十分なお金を稼ぐことができた。
                            </li>
                            <li>超絶美人のアメリカ人のお嫁さんができた。</li>
                        </ul>
                        <p style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
                            英語が人生を変え、
                            <br />
                            人生を豊かにするものだと身をもって
                            <br />
                            体感してきたからこそ、私は英語を教える意味があると思っています。
                        </p>
                        <p style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
                            「英語は、自分の可能性を最大限広げてくれる
                            <br />
                            最強のツール」
                        </p>
                        <p style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
                            だから、
                            <br />
                            資格取得がGOALになってしまいがちな、
                            <br />
                            つまらない日本の英語学習の常識を壊したい。
                        </p>
                        <ul>
                            <li>
                                過去の私のように自分のことを好きに
                                <br />
                                なれずに悶々と生きている。
                            </li>
                            <li>
                                現状を変えたくってたまらないけど
                                <br />
                                何をすればいいか分からない。
                            </li>
                            <li>
                                何かに挑戦したいけど、一歩踏み出すのが
                                <br />
                                怖い。
                            </li>
                        </ul>
                        <p style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
                            そういうネガティブな気持ちが
                            <br />
                            痛いほどに分かるから私だからこそ
                            <br />
                            英語コーチングを通して人の人生を
                            <br />
                            変えたいと想い、はじめました。
                        </p>
                    </div>
                </div>
                <div style={{ display: "flex", width: "100%", maxWidth: "650px", justifyContent: "center" }}>
                    <img src={WhyCoachingImage2} alt="" style={{ width: "100%" }} />
                </div>
            </div>
        </>
    );
};