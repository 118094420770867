import React, { useEffect, useState } from 'react';

import HeaderNav from "../../components/HeaderNav";
import HeaderNavMobile from '../../components/HeaderNavMobille';
import ServiceTitleImage from "../../assets/ServiceTitle.png";
import EndMessage from "../../components/EndMessage";
import Footer from "../../components/Footer";
import ServiceMessage from "./components/ServiceMessage";
import ServicePlan from "./components/ServicePlan";
import EnglishCoach from "./components/EnglishCoach";
import ServicePlanMobile from './components/ServicePlanMobile';
import HeaderNavSmartphone from '../../components/HeaderNavSmartphone';
import ServiceMessageSmartphone from './components/ServiceMessageSmartphone';
import ServicePlanSmartphone from './components/ServicePlanSmartphone';
import FooterSmartphone from '../../components/FooterSmartphone';
import EndMessageSmartphone from '../../components/EndMessageSmartphone';


function Service () {
    const [isMobile, setIsMobile] = useState(false);
    const [isNarrow, setIsNarrow] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 955);
            setIsNarrow(window.innerWidth <= 650);
        };


        const handleInitialResize = () => {
            handleResize();
            window.removeEventListener('resize', handleInitialResize);
        };

        handleInitialResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>    
            { isMobile ? ( isNarrow ? <HeaderNavSmartphone /> : <HeaderNavMobile />) : <HeaderNav /> }
            <div style={{ marginBottom: "20px", width: "100%", maxWidth: "1440px" }}>
                <img src={ServiceTitleImage} alt="" style={{ width: "100%" }} />
            </div>
            {/* ServiceMessage */}
            { isNarrow ? <ServiceMessageSmartphone /> : <ServiceMessage /> }
            {/* ServicePlan */}
            { isMobile ? ( isNarrow ? <ServicePlanSmartphone /> : <ServicePlanMobile /> ) : <ServicePlan /> }
            {/* EnglishCoach */}
            <EnglishCoach />
            { isNarrow ? <EndMessageSmartphone /> : <EndMessage />}
            {/* ラストメッセージ コンポーネントとして切り出す↑ */}
            { isNarrow ? <FooterSmartphone /> : <Footer />}
        </>
    );
}

export default Service;
