import CompanyLogo from "../assets/logo.png";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function HeaderNav () {
    const [hoveredButton, setHoveredButton] = useState(0);
    const hoverOn = (buttonId) => {
        setHoveredButton(buttonId);
    };
    const hoverOff = () => {
        setHoveredButton(0);
    };
    const hoverStyle = {
        backgroundColor: "#7BB6E380"
    };
    return (
        <div
            className="Header" style={{ width: "100%", maxWidth: "1440px" }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    padding: "20px 60px",
                    maxWidth: "1440px",
                }}
            >
                <Link
                    to="/"
                    className="HeaderLogo"
                    style={{ maxWidth: "239px" }}
                >
                    <img src={CompanyLogo} alt="" style={{ width: "100%" }}/>
                </Link>
                <nav className="HeaderNav" style={{ alignItems: "center" }}>
                    <ul 
                        style={{
                            display: "flex",
                            listStyle: "none"
                        }}
                    >
                        <Link
                            to="/"
                            style={{ textDecoration: "none", ...(hoveredButton === 1 && hoverStyle) }}
                            onMouseEnter={() => hoverOn(1)}
                            onMouseLeave={hoverOff}
                        >
                            <li style={{ padding: "20px 40px", color: "#000000" }}>TOP</li>
                        </Link>
                        <Link
                            to="/profile"
                            style={{ textDecoration: "none", ...(hoveredButton === 2 && hoverStyle) }}
                            onMouseEnter={() => hoverOn(2)}
                            onMouseLeave={hoverOff}
                        >
                            <li style={{ padding: "20px 40px", color: "#000000" }}>PROFILE</li>
                        </Link>
                        <Link
                            to="/service"
                            style={{ textDecoration: "none", ...(hoveredButton === 3 && hoverStyle) }}
                            onMouseEnter={() => hoverOn(3)}
                            onMouseLeave={hoverOff}
                        >
                            <li style={{ padding: "20px 40px", color: "#000000" }}>SERVICE</li>
                        </Link>
                        {/* 20230803追加↓ */}
                        <Link
                            to="/company"
                            style={{ textDecoration: "none", ...(hoveredButton === 4 && hoverStyle) }}
                            onMouseEnter={() => hoverOn(4)}
                            onMouseLeave={hoverOff}
                        >
                            <li style={{ padding: "20px 40px", color: "#000000" }}>COMPANY</li>
                        </Link>
                        {/* 20230803追加↑ */}
                        <a
                            href="https://line.me/R/ti/p/@453ltrvn"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", ...(hoveredButton === 5 && hoverStyle) }}
                            onMouseEnter={() => hoverOn(5)}
                            onMouseLeave={hoverOff}
                        >
                            <li style={{ padding: "20px 40px", color: "#000000" }}>CONTACT</li>
                        </a>
                    </ul>
                </nav>
            </div>
        </div>
    )
}