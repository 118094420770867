import CoachImage from "../../../assets/coach.png";
import CommunityImage from "../../../assets/community.png";

export default function ServicePlanSmartphone () {
    return (
        <>
            <div style={{ backgroundColor: "#EFEFEF", width: "100%", maxWidth: "650px", paddingTop: "50px", paddingBottom: "100px" }}>
                <div style={{ marginBottom: "20px", width: "100%", maxWidth: "650px"}}>
                    <p style={{ marginTop: "0px", marginBottom: "15px", fontSize: "40px", fontFamily: "游ゴシック体", fontWeight: "800", lineHeight: "40px", textAlign: "center" }}>
                        Service
                    </p>
                    <p style={{marginTop: "0px", marginBottom: "0px", fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        2種類のプランであなたの悩みを解決します
                    </p>
                </div>
                <div style={{ width: "100%", maxWidth: "650px", backgroundColor: "#EFEFEF" }}>
                    <div style={{ marginBottom: "30px", display: "flex", justifyContent: "center",  width: "100%", maxWidth: "650px" }}>
                        <div style={{ width: "100%", maxWidth: "300px", backgroundColor: "#FFFFFF", paddingTop: "20px", paddingBottom: "20px" }}>
                            <p style={{ marginTop: "0px", marginBottom: "20px", width: "100%", fontSize: "34px", fontFamily: "游明朝体", textAlign: "center" }}>
                                English Coach
                            </p>
                            <div style={{ marginBottom: "20px", display: "flex", width: "100%", maxWidth: "300px", justifyContent: "center" }}>
                                <img src={CoachImage} alt="" style={{ width: "100%" }} />
                            </div>
                            <p style={{ marginTop: "0px", marginBottom: "0px", width: "100%", fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                                1on1であなたのbuddyに。
                            </p>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center",  width: "100%", maxWidth: "650px" }}>
                        <div style={{ width: "100%", maxWidth: "300px", backgroundColor: "#FFFFFF", paddingTop: "20px", paddingBottom: "20px" }}>
                            <p style={{ marginTop: "0px", marginBottom: "5px", width: "100%", fontSize: "34px", fontFamily: "游明朝体", textAlign: "center" }}>
                                Community
                            </p>
                            <div style={{ marginBottom: "20px", display: "flex", width: "100%", maxWidth: "300px", justifyContent: "center" }}>
                                <img src={CommunityImage} alt="" style={{ width: "100%" }} />
                            </div>
                            <p style={{ marginTop: "0px", marginBottom: "0px", width: "100%", fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                                志が同じ仲間と一緒に頑張れる場所。
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "50px" }}>
                    <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        ≪申し込み・無料相談ご希望の方≫
                        <br />
                        下記公式LINEより、
                        <br />
                        【申し込み】or【無料相談】とお送りください。
                    </p>
                    <a 
                        href="https://line.me/R/ti/p/@453ltrvn"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <button
                            style={{ marginTop: "20px", maxWidth: "190px", width: "100%", background: "linear-gradient(to right, #013965, #FFFFFF)", color: "#FFFFFF", fontWeight: "600", border: "none", cursor: "pointer", paddingTop: "10px", paddingBottom: "10px", textDecoration: "none" }}
                        >
                            公式LINE
                        </button>
                    </a>
                </div>
            </div>
        </>
    );
}