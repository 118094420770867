import React, { useEffect, useState } from 'react';

import HeaderNav from "../../components/HeaderNav";
import Footer from "../../components/Footer";
import EndMessage from "../../components/EndMessage";
import AboutMe from "./components/AboutMe";
import Service from "./components/Service";
import Voice from "./components/Voice";
import HeaderNavMobile from '../../components/HeaderNavMobille';
import AboutMeMobile from './components/AboutMeMobile';
import ServiceMobile from './components/ServiceMobile';
import VoiceMobile from './components/VoiceMobile';
import HeaderNavSmartphone from '../../components/HeaderNavSmartphone';
import AboutMeSmartphone from './components/AboutMeSmartphone';
import ServiceSmartphone from './components/ServiceSmartphone';
import VoiceSmartphone from './components/VoiceSmartphone';
import EndMessageSmartphone from '../../components/EndMessageSmartphone';
import FooterSmartphone from '../../components/FooterSmartphone';
import TopMessage from './components/TopMessage';
import TopMessageSmartphone from './components/TopMessageSmartphone';

function Main() {
    const [isMobile, setIsMobile] = useState(false);
    const [isNarrow, setIsNarrow] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 955);
            setIsNarrow(window.innerWidth <= 650);
        };


        const handleInitialResize = () => {
            handleResize();
            window.removeEventListener('resize', handleInitialResize);
        };

        handleInitialResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
    <>   
        { isMobile ? ( isNarrow ? <HeaderNavSmartphone /> : <HeaderNavMobile />) : <HeaderNav /> }
        {/* トップメッセージ↓ */}
        { isNarrow ? <TopMessageSmartphone /> : <TopMessage /> }
        {/* About me↓ */}
        { isMobile ? ( isNarrow ? <AboutMeSmartphone /> : <AboutMeMobile /> ) : <AboutMe /> }
        {/* SERVICE↓ */}
        { isMobile ? ( isNarrow ? <ServiceSmartphone /> : <ServiceMobile /> ) : <Service /> }
        {/* VOICE コンポーネントとして切り出す↓ */}
        { isMobile ? ( isNarrow ? <VoiceSmartphone /> : <VoiceMobile /> ) : <Voice />}
        {/* VOICE コンポーネントとして切り出す↑ */}
        {/* ラストメッセージ コンポーネントとして切り出す↓ */}
        { isNarrow ? <EndMessageSmartphone /> : <EndMessage />}
        {/* ラストメッセージ コンポーネントとして切り出す↑ */}
        { isNarrow ? <FooterSmartphone /> : <Footer />}
    </>
    );
  }
  
  export default Main;