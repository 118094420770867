import { useState } from "react";
import { Link } from "react-router-dom";

import CoachImage from "../../../assets/coach.png";
import CommunityImage from "../../../assets/community.png";
import ServiceMobileImage from "../../../assets/SERVICE_Mobile.png";

export default function ServiceMobile () {
    const [isMoreButton, setIsMoreButton] = useState(0);
    const moreButtonOn = (moreButtonId) => {
        setIsMoreButton(moreButtonId);
    };
    const moreButtonOff = () => {
        setIsMoreButton(0);
    };
    const buttonStyle = {
        backgroundColor: "#033B67",
        cursor: "pointer",
        fontWeight: "700"
    };

    return (
        <>
            <div className="Service" style={{ width: "100%", maxWidth: "955px", paddingTop: "80px", paddingBottom: "80px", backgroundColor: "#efefef"}}>
                <div style={{ marginTop: "0px", display: "flex", width: "100%", maxWidth: "955px", justifyContent: "center" }}>
                    <img src={ServiceMobileImage} alt="" style={{ margin: "0px", width: "100%", maxWidth: "700px" }} />
                </div>
                <p 
                    style={{ 
                        margin: "0px",
                        fontSize: "34px",
                        fontFamily: "游ゴシック体",
                        fontWeight: "bold",
                        maxWidth: "1440px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    サービス
                </p>
                <p
                        style={{
                            fontSize: "20px",
                            fontFamily: "游ゴシック体",
                            width: "100%",
                            maxWidth: "1440px",
                            textAlign: "center",
                            marginTop: "49px",
                            marginBottom: "62px"
                        }}
                >
                    2種類のプランであなたの悩みを解決します
                </p>
                <div style={{ marginBottom: "0px", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                    <div>
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "100px", width: "100%" }}>
                            <div style={{ marginRight: "50px", width: "100%", maxWidth: "350px", maxHeight: "488px", backgroundColor: "#FFFFFF", textAlign: "center" }}>
                                <p style={{ fontSize: "28px", fontFamily: "游明朝体" }}>English Coach</p>
                                <img src={CoachImage} alt="" style={{ height: "100%", maxHeight: "200px" }} />
                                <p style={{ display: "inline-block", width: "100%", fontSize: "14px", fontFamily: "游ゴシック体" }}>
                                    1on1であなたのbuddyに。
                                    <br />
                                    <br />
                                    あなたの専属コーチとなり、
                                    <br />
                                    オリジナルカリキュラムを作成します。
                                    <br />
                                    本質的な目標設定と毎週の面談を通して
                                    <br />
                                    目標達成を本気で支援します。
                                </p>
                            </div>
                            <div style={{ width: "100%", maxWidth: "350px", maxHeight: "488px", backgroundColor: "#FFFFFF", textAlign: "center" }}>
                                <p style={{ fontSize: "28px", fontFamily: "游明朝体" }}>Community</p>
                                <img src={CommunityImage} alt="" style={{ height: "100%", maxHeight: "200px" }} />
                                <p style={{ display: "inline-block", width: "100%", fontSize: "14px", fontFamily: "游ゴシック体" }}>
                                    志が同じ仲間と一緒に頑張れる場所。
                                    <br />
                                    英語本来の楽しさをメンバーみんなでわかち合い、
                                    <br />
                                    成長を喜び合える環境です。
                                    <br />
                                    <br />
                                    英語を通して繋がり、
                                    <br />
                                    ワクワクする未来を共に作りましょう！
                                </p>
                            </div>
                        </div>
                        <div style={{ display: "flex", maxWidth: "955px", width: "full", justifyContent: "center" }}>
                            <Link to="/service">
                                <button
                                    style={{
                                        height: "50px",
                                        width: "280px",
                                        backgroundColor: "#7BB6E3",
                                        border: "none",
                                        color: "#FFFFFF",
                                        fontSize: "16px",
                                        ...(isMoreButton === 1 && buttonStyle)
                                    }}
                                    onMouseEnter={() => moreButtonOn(1)}
                                    onMouseLeave={moreButtonOff}
                                >
                                    MORE
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};