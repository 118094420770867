import ProfileImage from "../../../assets/profile.png";
import LineImage from "../../../assets/line.png";

export default function MasatoProfileMobile () {
    return (
        <>
            <div style={{ marginBottom: "200px", display: "flex", width: "100%", maxWidth: "955px", justifyContent: "center" }}>
                <div>
                    <img src={ProfileImage} alt="" />
                </div>
                <div>
                    <p style={{ marginTop: "0px", marginBottom: "30px", fontSize: "50px", fontFamily: "ヒラギノ角ゴ Std", fontWeight: "800", lineHeight: "50px", letterSpacing: "0.05em", color: "#AAAAAA" }}>
                        MASATO
                    </p>
                    <div>
                        <div style={{ display: "flex", height: "40px", alignItems: "center"}}>
                            <img src={LineImage} alt="" style={{ marginRight: "20px", height: "1px" }}/>
                            <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "600", letterSpacing: "0.05em", lineHeight: "20px", color: "#33333" }}>
                                English Coach.
                            </p>
                        </div>
                        <div style={{ marginBottom: "10px", fontSize: "12px" }}>
                            <ul style={{ paddingLeft: "15px" }}>
                                <li>英語コーチング</li>
                                <li>英語コーチ養成講座</li>
                                <li>英語コミュニティ【Englisholic】を運営</li>
                                <li>ライフコーチング</li>
                            </ul>
                        </div>
                        <div style={{ marginTop: "40px", marginBottom: "10px", display: "flex", height: "30px", maxWidth: "150px", alignItems: "center", borderBottomWidth: "1px", borderBottomStyle: "solid", borderColor: "#AAAAAA"}}>
                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "600", letterSpacing: "0.05em", lineHeight: "24px", color: "#33333" }}>
                                Career
                            </p>
                        </div>
                        <div style={{ display: "flex", fontSize: "12px", fontFamily: "游ゴシック体", lineHeight: "16px" }}>
                            <div style={{ marginRight: "10px" }}>
                                <p style={{ whiteSpace: "nowrap" }}>2020年</p>
                                <p style={{ whiteSpace: "nowrap" }}>2021年</p>
                                <p style={{ whiteSpace: "nowrap" }}>
                                    <br />
                                    2022年
                                </p>
                            </div>
                            <div>
                                <p style={{ whiteSpace: "nowrap" }}>公立中学校教師</p>
                                <p style={{ whiteSpace: "nowrap" }}>
                                    IT系ベンチャーで英語コーチ
                                    <br />
                                    兼カスタマーサクセス</p>
                                <p style={{ whiteSpace: "nowrap" }}>英語コーチング事業立ち上げに携わる</p>
                                <p style={{ whiteSpace: "nowrap" }}>副業で英語コーチングを始める</p>
                                <p style={{ whiteSpace: "nowrap" }}>2月に個人事業主として開業</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};