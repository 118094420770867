import TopMessageImage from "../../../assets/TopMessage.png";

export default function TopMessage () {
    return (
        <>
            <div style={{ marginBottom: "0px", width: "100%", maxWidth: "1440px" }}>
                <img src={TopMessageImage} alt="" style={{ width: "100%" }} />
            </div>
        </>
    )
}