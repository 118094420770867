export default function WhatIsCoachingMobile () {
    return (
        <>
            <div style={{ marginBottom: "120px", display: "flex", width: "100%", maxWidth: "955px", textAlign: "center", justifyContent: "center" }}>
                <div>
                    <div style={{ width: "100%", maxWidth: "570px" }}>
                        <div style={{ width: "100%", maxWidth: "570px" }}>
                            <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "900", color: "#033B67" }}>
                                そもそもコーチングとは？
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                コーチングは、クライアントが理想の状態や目標を実現するために、
                                <br />
                                クライアント自身が考え行動することを促すコミュニケーションのことです。
                            </p>
                        </div>
                    </div>
                    <div style={{ width: "100%", maxWidth: "600px" }}>
                        <div style={{ width: "100%", maxWidth: "600px", paddingTop: "100px", paddingBottom: "100px" }}>
                            <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "900", color: "#033B67" }}>
                                英語コーチングとは？
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                本質的な人生全体での目標設定を行い、自分の在りたい姿、
                                <br />
                                未来の目標に向けてどのように考え行動していくかをサポートします。
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                例えば、TOEIC 800点を目標にするのではなく、
                                <br />
                                TOEIC 800点を取ったその先に何があるのかと言うことを目標設定コーチングで
                                <br />
                                明確にします。他の英語コーチングスクールにはいないライフコーチングの経験が
                                <br />
                                ある私だからこそできるセッションとなります。
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                そして、従来のティーチングではなく「勉強法を教える」ことにフォーカスし、
                                <br />
                                学習者が自走できる状態を作ります。
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                それぞれのニーズやステージにあわせてカスタマイズしたカリキュラムを通して、
                                <br />
                                英語を通してかなえていきたい願いに向けて伴走します。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};