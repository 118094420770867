import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const topPaths = ['/', '/profile', '/service']; // スクロールをトップに戻したいpathnameのリスト

  useEffect(() => {
    if (topPaths.includes(pathname)) {
      window.scrollTo(0, 0);
    }
  }, [pathname, topPaths]);

  return null;
};

export default ScrollToTop;