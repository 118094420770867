export default function EnglishCoachDetailSmartphone () {
    return (
        <>
            <div style={{ marginBottom: "50px", width: "100%", maxWidth: "1440px"}}>
                <p style={{ marginTop: "0px", marginBottom: "15px", fontSize: "40px", fontFamily: "游ゴシック体", fontWeight: "800", lineHeight: "40px", textAlign: "center" }}>
                    English Coach
                </p>
                {/* ライン追加 */}
                <img src="" alt="" />
            </div>
            <div style={{ marginBottom: "30px", width: "100%", maxWidth: "1440px" }}>
                <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "16px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                    あなたの専属コーチとなり、
                    <br />
                    オリジナルカリキュラムを作成します。
                    <br />
                    本質的な目標設定と毎週の面談を通して
                    <br />
                    目標達成を本気で支援します。
                </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginBottom: "100px", display: "flex", width: "100%", maxWidth: "300px", justifyContent: "center" }}>
                    <div style={{ width: "100%", maxWidth: "650px", paddingTop: "35px", paddingBottom: "35px", boxShadow: "0 0 8px #D6D6D6" }}>
                        <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "600", color: "#033B67", textAlign: "center" }}>
                            こんな方におすすめ
                        </p>
                        <div style={{ display: "flex", justifyContent: "center", fontSize: "14px", fontFamily: "游ゴシック体" }}>
                            <ul style={{ paddingLeft: "20px" }}>
                                <li>何から勉強すればいいか分からない人</li>
                                <li>継続して学習が続かない人</li>
                                <li>丁寧に教えてほしい人</li>
                                <li>自分にあった勉強法でやりたい人</li>
                                <li>終わっていない参考書の山が家にある人</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};