export default function ServiceMessageSmartphone () {
    return (
        <>
            <div style={{ marginBottom: "100px", display: "flex", width: "100%", maxWidth: "650px", justifyContent: "center" }}>
                <div>
                    <p style={{ fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        英語を学習する人には、
                        <br />
                        その先に今とは違うなりたい姿や、
                        <br />
                        やりたいことがあるはず。
                        <br />
                        そのくらい英語は、生きる世界を広げる
                        <br />
                        最強のツールであると思っています。
                    </p>
                    <p style={{ fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        しかし、世の中には勉強法がありふれていて
                        <br />
                        「英語の勉強をしたいけど、
                        <br />
                        何から手をつけたらいいわからない...」
                        <br />
                        「英語学習にチャレンジしたいけど、
                        <br />
                        1人じゃ継続できない...」
                        <br />
                        そんな悩みを持つ人が多くいるのも現実。
                    </p>
                    <p style={{ fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        でも、英語には可能性を無限に広げてくれる
                        <br />
                        インパクトがある。
                        <br />
                        だからこそ、英語コーチングを通して
                        <br />
                        「あなたの人生を変えるお手伝いをしたい。」
                        <br />
                        そんな想いでサービスを提供しています。
                    </p>
                </div>
            </div>
        </>
    );
};