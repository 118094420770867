import PurposeLastImage from "../../../assets/purpose_mobile.png";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function ServiceDetailMobile () {
    const [isServiceButton, setIsServiceButton] = useState(0);
    const serviceButtonOn = (serviceButtonId) => {
        setIsServiceButton(serviceButtonId);
    };
    const serviceButtonOff = () => {
        setIsServiceButton(0);
    };
    const buttonStyle = {
        backgroundColor: "#033B67",
        cursor: "pointer",
        fontWeight: "700"
    };

    return (
        <>
            <div style={{ width: "100%", maxWidth: "955px", paddingTop: "180px", paddingBottom: "71px", backgroundImage: `url(${PurposeLastImage})`, backgroundSize: "cover" }}>
                <p style={{ marginBottom: "100px", textAlign: "center" }}>サービスの詳細については、こちらをご覧ください。</p>
                <div style={{ display: "flex", width: "100%", maxWidth: "955px", justifyContent: "center" }}>
                    <Link to="/service">
                        <button
                            style={{
                                paddingTop: "23px",
                                paddingBottom: "23px",
                                paddingLeft: "112px",
                                paddingRight: "112px",
                                color: "#FFFFFF",
                                fontSize: "16px",
                                fontFamily: "游ゴシック体",
                                backgroundColor: "#7BB6E3",
                                border: "none",
                                cursor: "pointer",
                                ...(isServiceButton === 1 && buttonStyle)
                             }}
                            onMouseEnter={() => serviceButtonOn(1)}
                            onMouseLeave={serviceButtonOff}
                        >
                            SERVICE
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
};