import VoiceMobileImage from "../../../assets/VOICE_mobile.png";
import Voice1Image from "../../../assets/voice1.png";
import Voice2Image from "../../../assets/voice2.png";
import Voice3Image from "../../../assets/voice3.png";

export default function VoiceSmartphone () {
    return (
        <>
            <div style={{ width: "100%", maxWidth: "650px", paddingTop: "80px", paddingBottom: "80px" }}>
                <div>
                    <div style={{ display: "flex", width: "100%", maxWidth: "650px", justifyContent: "center" }}>
                        <img src={VoiceMobileImage} alt="" style={{ margin: "0px", width: "100%", maxWidth: "700px" }} />
                    </div>
                    <p 
                        style={{
                            marginTop: "0px",
                            marginBottom: "50px",
                            width: "100%",
                            maxWidth: "650px",
                            textAlign: "center",
                            fontSize: "34px",
                            fontFamily: "游ゴシック体",
                            fontWeight: "bold",
                        }}
                    >
                        お客様の声
                    </p>
                    {/* お客様の声の下にアンダーライン追加 */}
                </div>
                {/* お客様の声Yさん */}
                <div style={{ width: "100%", maxWidth: "650px" }}>
                    <div style={{ marginBottom: "60px", width: "100%", maxWidth: "700px" }}>
                        <div
                            style={{
                                margin: "auto",
                                width: "100%",
                                maxWidth: "850px",
                                paddingTop: "30px",
                                paddingBottom: "30px",
                                backgroundColor: "#7BB6E3",
                                borderRadius: "80px"
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "center", marginRight: "0px", maxWidth: "650px" }}>
                                <img src={Voice1Image} alt="" style={{ width: "100%", maxWidth: "200px" }} />
                            </div>
                            <p style={{ fontSize: "16px", color: "#FFFFFF", lineHeight: "1.75rem", verticalAlign: "middle", textAlign: "center" }}>
                                <span style={{ fontWeight: "800", lineHeight: "2.5rem" }}>Yさん / 20代男性・外資系企業</span>
                                <br />
                                まさとさんのコーチングを受けて、
                                <br />
                                夢が叶いました。 
                                <br />
                                受講前と受講後の状態は、
                                <br />
                                モチベーション・考え方・行動・目標など
                                <br />
                                全てにおいて全く別人になったかのように
                                <br />
                                成長したと実感しています。
                                <br />
                                コーチングは、目標を明確にします。
                                <br />
                                それを達成するために何をすべきかを
                                <br />
                                自分の意見を尊重しつつ、
                                <br />
                                軌道を目標達成に導いてくれます。
                                <br />
                                また、全てを肯定してくれるので
                                <br />
                                行動するのがとても楽しくなり
                                <br />
                                毎日充実感が味わえます。
                                <br />
                                このように人生をより良いものにするために
                                <br />
                                コーチングは必要だと
                                <br />
                                身に染みて感じました。
                            </p>
                        </div>
                    </div>
                </div>
                {/* お客様の声Tさん */}
                <div style={{ width: "100%", maxWidth: "650px" }}>
                    <div style={{ marginBottom: "60px", width: "100%" }}>
                        <div
                            style={{
                                margin: "auto",
                                width: "100%",
                                maxWidth: "850px",
                                paddingTop: "30px",
                                paddingBottom: "30px",
                                backgroundColor: "#FFFFFF",
                                alignItems: "center",
                                borderRadius: "80px",
                                boxSizing:"border-box",
                                borderWidth: "2px",
                                borderColor: "#7BB6E3",
                                borderStyle: "solid"
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "center", marginRight: "0px", maxWidth: "650px" }}>
                                <img src={Voice2Image} alt="" style={{ width: "100%", maxWidth: "200px" }} />
                            </div>
                            <p style={{ fontSize: "16px", color: "#000000", lineHeight: "1.75rem", verticalAlign: "middle", textAlign: "center" }}>
                                <span style={{ fontWeight: "800", lineHeight: "2.5rem" }}>Tさん / 30代男性・エンジニア</span>
                                <br />
                                まさとさんのコーチングを受けようと
                                <br />
                                思った理由は、挑戦し続けている
                                <br />
                                まさとさんのようになりたいと
                                <br />
                                思ったからです。 
                                <br />
                                裏表がなく、
                                <br />
                                第一印象もとてもいい人でした。
                                <br />
                                受講前と後では
                                <br />
                                「なぜ英語を勉強したいか？」
                                <br />
                                がクリアになりました。
                                <br />
                                そして、「やらなきゃ」から
                                <br />
                                「やりたい」になり、
                                <br />
                                勉強が楽しくなりました。
                                <br />
                                迷った時、不安になった時に
                                <br />
                                「まさとさんに相談すればどうにかなる！」
                                <br />
                                そんな風にも思うようになりました。（笑）
                            </p>
                        </div>
                    </div>
                </div>
                {/* お客様の声Hさん */}
                <div style={{ width: "100%", maxWidth: "650px" }}>
                    <div style={{ marginBottom: "60px", width: "100%", maxWidth: "700px" }}>
                        <div
                            style={{
                                margin: "auto",
                                width: "100%",
                                maxWidth: "850px",
                                paddingTop: "30px",
                                paddingBottom: "30px",
                                backgroundColor: "#7BB6E3",
                                borderRadius: "80px"
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "center", marginRight: "0px", maxWidth: "650px" }}>
                                <img src={Voice3Image} alt="" style={{ width: "100%", maxWidth: "200px" }} />
                            </div>
                            <p style={{ fontSize: "16px", color: "#FFFFFF", lineHeight: "1.75rem", verticalAlign: "middle", textAlign: "center" }}>
                                <span style={{ fontWeight: "800", lineHeight: "2.5rem" }}>Hさん / 20代女性・大学生</span>
                                <br />
                                自分の将来の夢について
                                <br />
                                話を親身になって聞いてくれ、
                                <br />
                                サポートをしていただけると
                                <br />
                                感じたからです。
                                <br />
                                コーチングを受ける前までは
                                <br />
                                学習習慣がほとんどなかったですが、
                                <br />
                                コーチと一緒に勉強のやり方や
                                <br />
                                学習内容などを
                                <br />
                                決める学習習慣がつきました。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};