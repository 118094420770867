import TopMessageSmartphoneImage from "../../../assets/TopMesssageSmartphone.png";

export default function TopMessageSmartphone () {
    return (
        <>
            <div style={{ marginBottom: "0px", width: "100%", maxWidth: "1440px" }}>
                <img src={TopMessageSmartphoneImage} alt="" style={{ width: "100%" }} />
            </div>
        </>
    )
}