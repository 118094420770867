import { useState } from "react";
import { Link } from "react-router-dom";

import AboutMeImage from "../../../assets/AboutMe.png";
import ProfileImage from "../../../assets/profile.png";
import LineImage from "../../../assets/line.png";

export default function AboutMe () {
    const [isMoreButton, setIsMoreButton] = useState(0);
    const moreButtonOn = (moreButtonId) => {
        setIsMoreButton(moreButtonId);
    };
    const moreButtonOff = () => {
        setIsMoreButton(0);
    };
    const buttonStyle = {
        backgroundColor: "#033B67",
        cursor: "pointer",
        fontWeight: "700"
    };

    return (
        <>
            <div style={{ marginTop: "0px", width: "100%", maxWidth: "1440px" }}>
                <p 
                    style={{ 
                        marginTop: "80px",
                        marginBottom: "0px",
                        width: "100%",
                        maxWidth: "1440px",
                        fontSize: "34px",
                        fontFamily: "游ゴシック体",
                        fontWeight: "bold",
                        lineHeight: "34px",
                        textAlign: "center",
                    }}
                >
                    プロフィール
                </p>
                <div style={{ marginBottom: "80px", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                    <img src={AboutMeImage} alt="" />
                    <div className="ProfileImage" style={{ }}>
                        <img src={ProfileImage} alt="" style={{ width: "100%" }}/>
                    </div>
                    <div style={{ }}>
                        {/* プロフィールの下にアンダーライン追加 */}
                        <div className="ProfileContents" style={{ display: "flex" }}>
                            <div style={{ marginLeft: "30px", width: "100%" }}>
                                <p
                                    style={{ marginTop: "0px", marginBottom: "20px", width: "100%",fontSize: "40px", fontFamily: "ヒラギノ角ゴ Std", color: "#7d7d7d" }}
                                >
                                    MASATO
                                </p>
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px", height: "30px", width: "100%"  }}>
                                    <img src={LineImage} alt="" style={{ marginRight: "36px", height: "1px" }} />
                                    <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", color: "#333333" }}>English Coach.</p>
                                </div>
                                <p style={{ fontSize: "18px", marginTop: "0px", width: "100%" }}>英語コーチ / TOEIC920 / 元中学校英語教師</p>
                                <p
                                    style={{ marginBottom: "100px", display: "inline-block", width: "100%", fontSize: "16px", fontFamily: "游ゴシック体", fontWeight: "500", lineHeight: "30px" }}
                                >
                                    大学卒業後、15歳からの憧れの職業であった公立中学校の中学校英語教師となる。
                                    <br />
                                    やりがいを持って働くものの、激務な働き方から学ぶことや挑戦することが
                                    <br />
                                    できなくなっていくことに葛藤を招き、転職を決意。
                                    <br />
                                    2021年、ITベンチャーで英語コーチ兼カスタマーサクセスとして従事し、
                                    <br />
                                    英語コーチング事業立ち上げに携わる。しかし、事業の撤退をきっかけに
                                    <br />
                                    「自分で人生をコントロールし、最高の人生を送る」と覚悟し、独立を目指す。
                                    <br />
                                    2022年2月の独立後は、英語コーチ養成講座を提供/英語コミュニティを運営/
                                    <br />
                                    ライフコーチングを提供。口癖は、「やりたいことを全部やろう。」
                                </p>
                                <Link to="/profile">
                                    <button
                                        style={{
                                            margin: "0 auto",
                                            display: "inline-block",
                                            height: "50px",
                                            width: "100%",
                                            maxWidth: "280px",
                                            backgroundColor: "#7BB6E3",
                                            border: "none",
                                            color: "#FFFFFF",
                                            fontSize: "16px",
                                            ...(isMoreButton === 1 && buttonStyle)
                                        }}
                                        onMouseEnter={() => moreButtonOn(1)}
                                        onMouseLeave={moreButtonOff}
                                    >
                                        MORE
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};