import CommunityImage1 from "../../../assets/Community1.jpg";
import CommunityImage2 from "../../../assets/Community2.jpg";

export default function CommunityMobile () {
    return (
        <>
            <div style={{ paddingBottom: "50px", width: "100%", maxWidth: "955px" }}>
                <div style={{ marginTop: "100px", marginBottom: "50px", width: "100%", maxWidth: "955px"}}>
                    <p style={{ marginTop: "0px", marginBottom: "15px", fontSize: "40px", fontFamily: "游ゴシック体", fontWeight: "800", lineHeight: "40px", textAlign: "center" }}>
                        Community
                    </p>
                    {/* ライン追加 */}
                    <img src="" alt="" />
                </div>
                <div style={{ marginBottom: "100px", width: "100%", maxWidth: "955px", textAlign: "center"}}>
                    <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "20px", fontFamily: "游ゴシック体" }}>英語好きが繋がるコミュニティ </p>
                    <p style={{ marginTop: "20px", marginBottom: "40px", fontSize: "34px", fontFamily: "游明朝体", fontWeight: "800", color: "#0F30BE" }}>Englisholic</p>
                    <p style={{ marginTop: "0px", marginBottom: "40px", fontSize: "16px", fontFamily: "游ゴシック体" }}>
                        英語本来の楽しさをメンバーみんなでわかち合い、
                        <br />
                        成長を喜び合える環境です。
                    </p>
                    <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "16px", fontFamily: "游ゴシック体" }}>
                        英語を通して繋がり、
                        <br />
                        ワクワクする未来を共に作りませんか？
                    </p>
                </div>
                <div style={{ marginBottom: "100px", display: "flex", width: "100%", maxWidth: "955px", justifyContent: "space-around", alignItems: "center" }}>
                    <div style={{ maxWidth: "350px" }}>
                        <img src={CommunityImage2} alt="" style={{ width: "100%" }} />
                    </div>
                    <div>
                        <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "700", color: "#033B67", textAlign: "center" }}>コミュニティを立ち上げた想い</p>
                        <p style={{ fontSize: "16px", fontFamily: "游ゴシック体", fontWeight: "500", textAlign: "center" }}>
                            英語は本来楽しいものなのに資格試験や
                            <br />
                            転職活動が原因でhave to状態
                            <br />
                            （やらされている状態）に陥ってしまう人が
                            <br />
                            多いようい感じていました。
                            <br />
                            英語本来の楽しさをメンバーみんなで分かり合い、
                            <br />
                            成長を喜び合える環境を作りたい！！
                            <br />
                            と思い、コミュニティを作ることを決めました。
                            <br />
                            英語を一緒に楽しみ、
                            <br />
                            仲間と共に成長していきましょう。
                        </p>
                    </div>
                </div>
                <div style={{ display: "flex", width: "100%", maxWidth: "955px", justifyContent: "space-around" }}>
                    <div>
                        <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "700", color: "#033B67", textAlign: "center" }}>コンテンツ</p>
                        <ul style={{ fontSize: "16px", fontFamily: "游ゴシック体", fontWeight: "500" }}>
                            <li>毎週土曜の英会話</li>
                            <li>毎月1回のスピーチコンテスト
                                <br />
                                （基本オンライン開催）
                            </li>
                            <li>1on1の相談会</li>
                            <li>オフラインのイベント（不定期）</li>
                            <li>Twitter感覚で英語の投稿</li>
                            <p style={{ textAlign: "right" }}>その他・・・</p>
                        </ul>
                    </div>
                    <div style={{ maxWidth: "350px" }}>
                        <img src={CommunityImage1} alt="" style={{ width: "100%" }} />
                    </div>
                </div>
            </div>
        </>
    );
};