import React, { useEffect, useState } from 'react';

import HeaderNav from "../../components/HeaderNav";
import HeaderNavMobile from '../../components/HeaderNavMobille';
import HeaderNavSmartphone from '../../components/HeaderNavSmartphone';
import Footer from "../../components/Footer";
import FooterSmartphone from '../../components/FooterSmartphone';
import CompanyView from './components/CompanyView';
import CompanySmartphoneView from './components/CompanySmartphoneView';

function Company () {
    const [isMobile, setIsMobile] = useState(false);
    const [isNarrow, setIsNarrow] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 955);
            setIsNarrow(window.innerWidth <= 650);
        };


        const handleInitialResize = () => {
            handleResize();
            window.removeEventListener('resize', handleInitialResize);
        };

        handleInitialResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            { isMobile ? ( isNarrow ? <HeaderNavSmartphone /> : <HeaderNavMobile />) : <HeaderNav /> }
            { isNarrow ? <CompanySmartphoneView /> : <CompanyView /> }
            { isNarrow ? <FooterSmartphone /> : <Footer />}
        </>
    )
};

export default Company;