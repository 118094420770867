import { useState } from "react";
import { Link } from "react-router-dom";

// import AboutMeImage from "../../../assets/AboutMe.png";
import AboutMeMobileImage from "../../../assets/AboutMeMobile.png";
import ProfileImage from "../../../assets/profile.png";
// import LineImage from "../../../assets/line.png";

export default function AboutMeMobile () {
    const [isMoreButton, setIsMoreButton] = useState(0);
    const moreButtonOn = (moreButtonId) => {
        setIsMoreButton(moreButtonId);
    };
    const moreButtonOff = () => {
        setIsMoreButton(0);
    };
    const buttonStyle = {
        backgroundColor: "#033B67",
        cursor: "pointer",
        fontWeight: "700"
    };

    return (
        <>
            <div style={{ marginTop: "0px", width: "100%", maxWidth: "955px" }}>
                <div style={{ marginTop: "80px", display: "flex", width: "100%", maxWidth: "955px", justifyContent: "center" }}>
                    <img src={AboutMeMobileImage} alt="" style={{ margin: "0px", width: "100%", maxWidth: "700px" }} />
                </div>
                <p 
                    style={{ 
                        marginTop: "0px",
                        marginBottom: "50px",
                        width: "100%",
                        maxWidth: "955px",
                        fontSize: "34px",
                        fontFamily: "游ゴシック体",
                        fontWeight: "bold",
                        lineHeight: "34px",
                        textAlign: "center",
                    }}
                >
                    プロフィール
                </p>
                <p
                    style={{ marginTop: "0px", marginBottom: "20px", width: "100%", maxWidth: "955px", textAlign: "center",fontSize: "40px", fontFamily: "ヒラギノ角ゴ Std", color: "#7d7d7d" }}
                >
                    MASATO
                </p>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px", height: "30px", width: "100%", maxWidth: "955px", justifyContent: "center"  }}>
                    {/* <img src={LineImage} alt="" style={{ marginRight: "36px", height: "1px" }} /> */}
                    <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", color: "#333333" }}>English Coach</p>
                </div>
                <p style={{ fontSize: "18px", marginTop: "0px", width: "100%", maxWidth: "955px", textAlign: "center" }}>英語コーチ / TOEIC920 / 元中学校英語教師</p>
                <div style={{ marginBottom: "50px", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                    {/* <img src={AboutMeImage} alt="" /> */}
                    <div className="ProfileImage" style={{ maxWidth: "400px" }}>
                        <img src={ProfileImage} alt="" style={{ width: "100%" }}/>
                    </div>
                    {/* <div style={{ }}> */}
                        {/* プロフィールの下にアンダーライン追加 */}
                        <div className="ProfileContents" style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ marginLeft: "0px", width: "100%" }}>
                                <p
                                    style={{ display: "inline-block", width: "100%", fontSize: "12px", fontFamily: "游ゴシック体", fontWeight: "500", lineHeight: "30px" }}
                                >
                                    大学卒業後、15歳からの憧れの職業であった公立中学校の
                                    <br />
                                    中学校英語教師となる。やりがいを持って働くものの、
                                    <br />
                                    激務な働き方から学ぶことや挑戦することが
                                    <br />
                                    できなくなっていくことに葛藤を招き、転職を決意。
                                    <br />
                                    2021年、ITベンチャーで英語コーチ兼カスタマーサクセス
                                    <br />
                                    として従事し、英語コーチング事業立ち上げに携わる。
                                    <br />
                                    しかし、事業の撤退をきっかけに
                                    <br />
                                    「自分で人生をコントロールし、最高の人生を送る」
                                    <br />
                                    と覚悟し、独立を目指す。2022年2月の独立後は、
                                    <br />
                                    英語コーチ養成講座を提供/英語コミュニティを運営/
                                    <br />
                                    ライフコーチングを提供。
                                    <br />
                                    口癖は、「やりたいことを全部やろう。」
                                </p>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
                <Link to="/profile" style={{ marginBottom: "80px", display: "flex", justifyContent: "center", textDecoration: "none" }}>
                    <button
                        style={{
                            margin: "0 auto",
                            display: "inline-block",
                            height: "50px",
                            width: "100%",
                            maxWidth: "280px",
                            backgroundColor: "#7BB6E3",
                            border: "none",
                            color: "#FFFFFF",
                            fontSize: "16px",
                            textDecoration: "none",
                            ...(isMoreButton === 1 && buttonStyle)
                        }}
                        onMouseEnter={() => moreButtonOn(1)}
                        onMouseLeave={moreButtonOff}
                    >
                        MORE
                    </button>
                </Link>
            </div>
        </>
    );
};