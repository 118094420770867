import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import Main from '../pages/Main/Main';
import Profile from '../pages/Profile/Profile';
import Service from '../pages/Service/Service';
import Company from '../pages/Company/Company';

function AppRoutes() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/service" element={<Service />} />
        <Route path="/company" element={<Company />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
