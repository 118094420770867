import CompanyLogo from "../assets/logo.png";
import NoteImage from "../assets/note.png";
import InstaImage from "../assets/insta.png";
import TwitterImage from "../assets/twitter.png";
import YouTubeImage from "../assets/youtube.png";
import { Link } from "react-router-dom";

export default function Footer () {
    return (
        <div style={{ maxWidth: "1440px" }}>
            <div style={{ display: "flex", paddingTop: "55px", paddingBottom: "30px", alignItems: "center", justifyContent: "center" }}>
                <div style={{ display: "flex", maxWidth: "720px", width: "100%", justifyContent: "center" }}>
                    <div style={{ display: "flex", maxWidth: "720px", width: "100%", justifyContent: "center" }}>
                        <Link to="/">
                            <div style={{ maxWidth: "237px", width: "100%" }}>
                                <img src={CompanyLogo} alt="" style={{ maxWidth: "237px", width: "100%" }} />
                            </div>
                        </Link>
                    </div>
                </div>
                <div style={{ margin: "auto", display: "flex", maxWidth: "500px", width: "720px", alignItems: "center", justifyContent: "space-around" }}>
                    <a href="https://note.com/oimomam/" target="_blank" rel="noopener noreferrer" style={{ margin: "auto" }}>
                        <img src={NoteImage} alt="" />
                    </a>
                    <a href="https://www.instagram.com/masato_kaiho/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank" rel="noopener noreferrer" style={{ margin: "auto" }}>
                        <img src={InstaImage} alt="" />
                    </a>
                    <a href="https://twitter.com/Masato_KAIHO" target="_blank" rel="noopener noreferrer" style={{ margin: "auto" }}>
                        <img src={TwitterImage} alt="" />
                    </a>
                    <a href="https://www.youtube.com/@masato9009" target="_blank" rel="noopener noreferrer" style={{ margin: "auto" }}>
                        <img src={YouTubeImage} alt="" />
                    </a>
                </div>
            </div>
            <div style={{ width: "100%", maxWidth: "1440px" }}>
                <p style={{ width: "100%", maxWidth: "1440px", textAlign: "center", fontSize: "16px", fontFamily: "游ゴシック体", }}>
                    &copy;2023 合同会社KAIHO
                </p>
            </div>
        </div>
    )
};