import { Link } from "react-router-dom";
import CompanyLogo from "../assets/logo.png";
import MobileMenuImage from "../assets/MobileMenuLine.png";
import { useState } from "react";

export default function HeaderNavMobile () {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const menuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div style={{ position: "relative", width: "100%", maxWidth: "955px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                        padding: "20px 60px",
                        maxWidth: "955px",
                    }}
                >
                    <Link
                        to="/"
                        className="HeaderLogo"
                        style={{ maxWidth: "239px" }}
                    >
                        <img src={CompanyLogo} alt="" style={{ width: "100%" }}/>
                    </Link>
                    {/* MobileMenu */}
                    <button onClick={menuClick} style={{ backgroundColor: "#FFFFFF", border: "none" }}>
                        <img src={MobileMenuImage} alt="" />
                    </button>
                    { isMenuOpen && (
                        <div style={{ position: "fixed", zIndex: "10", top: "0", left: "0", height: "1200px", width: "100%", maxWidth: "955px", backgroundColor: "#7BB6E3" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", maxWidth: "955px" }}>
                                <div style={{ width: "100%", maxWidth: "955px", textAlign: "center" }}>
                                    <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                                        <Link to="/" onClick={menuClick} style={{ color: "#000000", textDecoration: "none", fontSize: "30px" }}>TOP</Link>
                                    </div>
                                    <div style={{ marginBottom: "50px" }}>
                                        <Link to="/profile" onClick={menuClick} style={{ color: "#000000", textDecoration: "none", fontSize: "30px" }}>PROFILE</Link>
                                    </div>
                                    <div style={{ marginBottom: "50px" }}>
                                        <Link to="/service" onClick={menuClick} style={{ color: "#000000", textDecoration: "none", fontSize: "30px" }}>SERVICE</Link>
                                    </div>
                                    {/* 20230803追加↓ */}
                                    <div style={{ marginBottom: "50px" }}>
                                        <Link to="/company" onClick={menuClick} style={{ color: "#000000", textDecoration: "none", fontSize: "30px" }}>COMPANY</Link>
                                    </div>
                                    {/* 20230803追加↑ */}
                                    <div style={{ marginBottom: "50px" }}>
                                        <a
                                            href="https://line.me/R/ti/p/@453ltrvn"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#000000", textDecoration: "none", fontSize: "30px"}}
                                        >
                                            CONTACT
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) }
                </div>
            </div>
        </>
    );
};