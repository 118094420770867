export default function WhatIsCoachingSmartphone () {
    return (
        <>
            <div style={{ marginBottom: "120px", display: "flex", width: "100%", maxWidth: "955px", textAlign: "center", justifyContent: "center" }}>
                <div>
                    <div style={{ width: "100%", maxWidth: "570px" }}>
                        <div style={{ width: "100%", maxWidth: "570px" }}>
                            <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "900", color: "#033B67" }}>
                                そもそもコーチングとは？
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                コーチングは、クライアントが
                                <br />
                                理想の状態や目標を実現するために、
                                <br />
                                クライアント自身が考え行動することを
                                <br />
                                促すコミュニケーションのことです。
                            </p>
                        </div>
                    </div>
                    <div style={{ width: "100%", maxWidth: "600px" }}>
                        <div style={{ width: "100%", maxWidth: "600px", paddingTop: "100px", paddingBottom: "0px" }}>
                            <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "900", color: "#033B67" }}>
                                英語コーチングとは？
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                本質的な人生全体での目標設定を行い、
                                <br />
                                自分の在りたい姿、未来の目標に向けて
                                <br />
                                どのように考え行動していくかを
                                <br />
                                サポートします。
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                例えば、TOEIC 800点を
                                <br />
                                目標にするのではなく、TOEIC 800点を
                                <br />
                                取ったその先に何があるのか
                                <br />
                                と言うことを目標設定コーチングで
                                <br />
                                明確にします。
                                <br />
                                他の英語コーチングスクールには
                                <br />
                                いないライフコーチングの経験が
                                <br />
                                ある私だからこそできる
                                <br />
                                セッションとなります。
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                そして、従来のティーチングではなく
                                <br />
                                「勉強法を教える」ことにフォーカスし、
                                <br />
                                学習者が自走できる状態を作ります。
                            </p>
                            <p style={{ fontSize: "16px", fontFamily: "游ゴシック体" }}>
                                それぞれのニーズやステージにあわせて
                                <br />
                                カスタマイズしたカリキュラムを通して、
                                <br />
                                英語を通してかなえていきたい願い
                                <br />
                                に向けて伴走します。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};