import React, { useEffect, useState } from 'react';

import HeaderNav from "../../components/HeaderNav";
import HeaderNavMobile from '../../components/HeaderNavMobille';
import ProfileTitleImage from "../../assets/profile_title.png";
import Footer from "../../components/Footer";
import MasatoProfile from "./components/MasatoProfile";
import WhyCoaching from "./components/WhyCoaching";
import Purpose from "./components/Purpose";
import ServiceDetail from "./components/ServiceDetail";
import MasatoProfileMobile from './components/MasatoProfileMobile';
import WhyCoachingMobile from './components/WhyCoachingMobile';
import ServiceDetailMobile from './components/ServiceDetailMobile';
import MasatoProfileSmartphone from './components/MasatoProfileSmartphone';
import HeaderNavSmartphone from '../../components/HeaderNavSmartphone';
import WhyCoachingSmartphone from './components/WhyCoachingSmartphone';
import PurposeSmartphone from './components/PurposeSmartphone';
import FooterSmartphone from '../../components/FooterSmartphone';
import ServiceDetailSmartphone from './components/ServiceDetailSmartphone';

export default function Profile () {
    const [isMobile, setIsMobile] = useState(false);
    const [isNarrow, setIsNarrow] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 955);
            setIsNarrow(window.innerWidth <= 650);
        };


        const handleInitialResize = () => {
            handleResize();
            window.removeEventListener('resize', handleInitialResize);
        };

        handleInitialResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>    
            { isMobile ? ( isNarrow ? <HeaderNavSmartphone /> : <HeaderNavMobile />) : <HeaderNav /> }
            <div style={{ marginBottom: "20px", width: "100%", maxWidth: "1440px" }}>
                <img src={ProfileTitleImage} alt="" style={{ width: "100%" }} />
            </div>
            {/* MasatoProfile↓ */}
            { isMobile ? ( isNarrow ? <MasatoProfileSmartphone /> : <MasatoProfileMobile /> ) : <MasatoProfile /> }
            {/* WhyCoaching↓ */}
            { isMobile ? ( isNarrow ? <WhyCoachingSmartphone /> : <WhyCoachingMobile /> ) : <WhyCoaching /> }
            {/* Purpose↓ */}
            { isNarrow ? <PurposeSmartphone /> : <Purpose /> }
            {/* ServiceDetail */}
            { isMobile ? ( isNarrow ? <ServiceDetailSmartphone /> : <ServiceDetailMobile /> ) : <ServiceDetail />}
            { isNarrow ? <FooterSmartphone /> : <Footer />}
        </>
    );
}

