export default function CompanyView () {
    return (
        <>
            <div style={{ maxWidth: "800px", backgroundColor: "", margin: "auto", padding: "50px" }}>
                <div style={{ marginBottom: "20px", backgroundColor: "" }}>
                    <p style={{ marginTop: "0px", marginBottom: "15px", fontSize: "40px", fontFamily: "游ゴシック体", fontWeight: "800", lineHeight: "40px", textAlign: "center" }}>
                        会社概要
                    </p>
                    <p style={{marginTop: "0px", marginBottom: "0px", fontSize: "16px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        COMPANY
                    </p>
                </div>
                <div style={{ padding: "30px" }}>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "150px", width: "100px", fontWeight: "800" }}>会社名</p>
                        <p>KAIHO合同会社</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "150px", width: "100px", fontWeight: "800" }}>代表者氏名</p>
                        <p>井上 真登</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "150px", width: "100px", fontWeight: "800" }}>本社所在地</p>
                        <p>大阪府八尾市山城町5丁目2番30号</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "150px", width: "100px", fontWeight: "800" }}>資本金</p>
                        <p>10万円</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "150px", width: "100px", fontWeight: "800" }}>事業内容</p>
                        <p>英語コーチング、コミュニティ運営</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "150px", width: "100px", fontWeight: "800" }}>お問い合せ</p>
                        <p>english-coach@kaiho.earth</p>
                    </div>
                </div>
            </div>
        </>
    );
};