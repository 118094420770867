export default function Purpose () {
    return (
        <>
            <div style={{ marginBottom: "200px", width: "100%", maxWidth: "1440px" }}>
                <div style={{ marginBottom: "120px", width: "100%", maxWidth: "1440px"}}>
                    <p style={{ marginTop: "0px", marginBottom: "15px", fontSize: "40px", fontFamily: "游ゴシック体", fontWeight: "800", lineHeight: "40px", textAlign: "center" }}>
                        Purpose
                    </p>
                    <p style={{marginTop: "0px", marginBottom: "0px", fontSize: "16px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        English Coachとしてどう生きていきたいのか  
                    </p>
                </div>
                <div style={{ width: "100%", maxWidth: "1440px", fontSize: "16px", fontFamily: "游ゴシック体" }}>
                    <p style={{ textAlign: "center" }}>
                        私のパーパス（存在意義）は、
                        <br />
                        「日本人を閉鎖的なマインドから解放し、自由に生きる人を増やす」こと。
                    </p>
                    <p style={{ textAlign: "center" }}>
                        そして、自分の願いに正直に生きる人を増やし、
                        <br />
                        個がもつ可能性を解放して生きていける世界を作っていきたいです。
                    </p>
                    <p style={{ textAlign: "center" }}>
                        私は、日本の「空気を読む文化」「出る杭は打たれる」「失敗を嘲笑う」
                        <br />
                        このような閉鎖的な文化を変えたいと思っています。
                    </p>
                    <p style={{ textAlign: "center" }}>
                        世界中の人と関わる中で「生きる世界は一つではなく、今ある生き方も
                        <br />
                        無限に存在する生き方のほんの1つでしかない」ということに気づきました。
                        <br />
                        そして、「どこで生きてもいいんだ」という事実に気づいてから
                        <br />
                        人生が生きやすくなりました。
                    </p>
                    <p style={{ textAlign: "center" }}>
                        私は、英語は生きる世界を広げるツールであると思っています。
                        <br />
                        だからこそ、英語を通して世の中の固定観念を取り払い、
                        <br />
                        自分らしい生き方を目指し、熱狂して人生を送る人を増やしたい。
                    </p>
                    <p style={{ textAlign: "center" }}>
                        そのためにまずは、私がどんなに怖くても挑戦し続けている状態で生きたい。
                    </p>
                    <p style={{ textAlign: "center" }}>
                        小さな行動の積み上げは、確実に自信と成長に繋がる。
                        <br />
                        そして、Have to ではなく Want to の願いで行動をし続けることは、人生を豊かにしてくれる。
                        <br />
                        これからも学び、そして行動し続け、
                        <br />
                        関わった人に”情報”と”エネルギー”を循環させていきたいと思っています。
                    </p>
                    <p style={{ textAlign: "center" }}>
                        私の人生を変えてくれた英語。
                        <br />
                        そんな英語をきっかけに人生を変え、自分の人生最高と言える人を増やし続けます。
                    </p>
                </div>
            </div>
        </>
    );
};