import FirstInterviewImage from "../../../assets/1st_interview.png";
import RightArrowImage from "../../../assets/RightArrow.png";
import StudyReportImage from "../../../assets/StudyReport.png";
import WeeklyReviewImage from "../../../assets/WeeklyReview.png";
import PlanUpdateImage from "../../../assets/PlanUpdate.png";
import GoalAchieveImage from "../../../assets/GoalAchieve.png";

export default function SessionSmartphone () {
    return (
        <>
            <div style={{ paddingTop: "10px", paddingBottom: "50px", width: "100%", maxWidth: "955px", backgroundColor: "#EFEFEF" }}>
                    <p style={{ fontSize: "20px", fontFamily: "游ゴシック体", fontWeight: "900", color: "#033B67", textAlign: "center" }}>
                        セッションの流れ
                    </p>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div>
                            {/* 初回面談 */}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ maxWidth: "310px", flex: "1", backgroundColor: "#FFFFFF", borderRadius: "1.5rem", borderWidth: "2px", borderStyle: "solid", borderColor: "#016ACB" }}>
                                    <p
                                        style={{marginTop: "0px", marginBottom: "0px", fontSize: "16px", fontFamily: "游ゴシック体", color: "#FFFFFF", textAlign: "center", paddingTop: "15px", paddingBottom: "15px", backgroundColor: "#016ACB", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}
                                    >
                                        初回面談
                                    </p>
                                    <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center", backgroundColor: "#FFFFFF", padding: "10px", borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                        <img src={FirstInterviewImage} alt="" />
                                        <div style={{ display: "flex", minHeight: "112px", alignItems: "center" , justifyContent: "center" }}>
                                            <ul style={{ fontSize: "12px", fontFamily: "游ゴシック体", paddingLeft: "15px" }}>
                                                <li style={{ textAlign: "left" }}>目標設定</li>
                                                <li style={{ textAlign: "left" }}>現状と目標のGAPの明確化</li>
                                                <li style={{ textAlign: "left" }}>カリキュラム<br />作成</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", maxWidth: "955px" }}>
                                <img src={RightArrowImage} alt="" style={{ height: "58px", width: "24px", transform: "rotate(90deg)" }} />
                            </div>
                            {/* 学習報告 */}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ maxWidth: "310px", flex: "1", backgroundColor: "#FFFFFF", borderRadius: "1.5rem", borderWidth: "2px", borderStyle: "solid", borderColor: "#016ACB" }}>
                                    <p
                                        style={{marginTop: "0px", marginBottom: "0px", fontSize: "16px", fontFamily: "游ゴシック体", color: "#FFFFFF", textAlign: "center", paddingTop: "15px", paddingBottom: "15px", backgroundColor: "#016ACB", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}
                                    >
                                        学習報告
                                    </p>
                                    <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center", backgroundColor: "#FFFFFF", padding: "10px", borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                        <img src={StudyReportImage} alt="" />
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ul style={{ fontSize: "12px", fontFamily: "游ゴシック体", paddingLeft: "15px" }}>
                                                <li style={{ textAlign: "left" }}>24時間質問し放題</li>
                                                <li style={{ textAlign: "left" }}>学習の習慣化</li>
                                                <li style={{ textAlign: "left" }}>音声添削</li>
                                                <li style={{ textAlign: "left" }}>フィードバック</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", maxWidth: "955px" }}>
                                <img src={RightArrowImage} alt="" style={{ height: "58px", width: "24px", transform: "rotate(90deg)" }} />
                            </div>
                            {/* 週間面談 */}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ maxWidth: "310px", flex: "1", backgroundColor: "#FFFFFF", borderRadius: "1.5rem", borderWidth: "2px", borderStyle: "solid", borderColor: "#016ACB" }}>
                                    <p
                                        style={{marginTop: "0px", marginBottom: "0px", fontSize: "16px", fontFamily: "游ゴシック体", color: "#FFFFFF", textAlign: "center", paddingTop: "15px", paddingBottom: "15px", backgroundColor: "#016ACB", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}
                                    >
                                        週間面談
                                    </p>
                                    <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center", backgroundColor: "#FFFFFF", padding: "10px", borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                        <img src={WeeklyReviewImage} alt="" />
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ul style={{ fontSize: "12px", fontFamily: "游ゴシック体", paddingLeft: "15px" }}>
                                                <li style={{ textAlign: "left" }}>課題発見</li>
                                                <li style={{ textAlign: "left" }}>課題解決法の提案</li>
                                                <li style={{ textAlign: "left" }}>小テスト</li>
                                                <li style={{ textAlign: "left" }}>小レッスン<br />（英会話）</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", maxWidth: "955px" }}>
                                <img src={RightArrowImage} alt="" style={{ height: "58px", width: "24px", transform: "rotate(90deg)" }} />
                            </div>
                            {/* カリキュラム更新 */}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ maxWidth: "310px", flex: "1", backgroundColor: "#FFFFFF", borderRadius: "1.5rem", borderWidth: "2px", borderStyle: "solid", borderColor: "#016ACB" }}>
                                    <p
                                        style={{marginTop: "0px", marginBottom: "0px", fontSize: "16px", fontFamily: "游ゴシック体", color: "#FFFFFF", textAlign: "center", paddingTop: "15px", paddingBottom: "15px", backgroundColor: "#016ACB", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}
                                    >
                                        カリキュラム更新
                                    </p>
                                    <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center", backgroundColor: "#FFFFFF", padding: "10px", borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                        <img src={PlanUpdateImage} alt="" />
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ul style={{ fontSize: "12px", fontFamily: "游ゴシック体", paddingLeft: "15px" }}>
                                                <li style={{ textAlign: "left" }}>
                                                    学習段階に応じて
                                                    <br />
                                                    ステップアップ
                                                </li>
                                                <li style={{ textAlign: "left" }}>
                                                    より個別化された
                                                    <br />
                                                    学習プラン
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", maxWidth: "955px" }}>
                                <img src={RightArrowImage} alt="" style={{ height: "58px", width: "24px", transform: "rotate(90deg)" }} />
                            </div>
                            {/* 目標達成 */}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ maxWidth: "310px", flex: "1", backgroundColor: "#FFFFFF", borderRadius: "1.5rem", borderWidth: "2px", borderStyle: "solid", borderColor: "#016ACB" }}>
                                    <p
                                        style={{marginTop: "0px", marginBottom: "0px", fontSize: "16px", fontFamily: "游ゴシック体", color: "#FFFFFF", textAlign: "center", paddingTop: "15px", paddingBottom: "15px", backgroundColor: "#016ACB", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}
                                    >
                                        目標達成
                                    </p>
                                    <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center", backgroundColor: "#FFFFFF", padding: "10px", borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                        <img src={GoalAchieveImage} alt="" />
                                        <div style={{ margin: "auto", display: "flex", minHeight: "112px", alignItems: "center", justifyContent: "center" }}>
                                            <p style={{ fontSize: "12px", fontFamily: "游ゴシック体" }}>なりたい自分になって卒業</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
};