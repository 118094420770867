export default function CompanySmartphoneView () {
    return (
        <>
            <div style={{ maxWidth: "300px", margin: "auto" }}>
                <div style={{ marginBottom: "20px", backgroundColor: "" }}>
                    <p style={{ marginTop: "0px", marginBottom: "15px", fontSize: "30px", fontFamily: "游ゴシック体", fontWeight: "800", lineHeight: "40px", textAlign: "center" }}>
                        会社概要
                    </p>
                    <p style={{marginTop: "0px", marginBottom: "0px", fontSize: "14px", fontFamily: "游ゴシック体", textAlign: "center" }}>
                        COMPANY
                    </p>
                </div>
                <div style={{ padding: "10px" }}>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "10px", width: "100px", fontSize: "12px", fontWeight: "800" }}>会社名</p>
                        <p style={{ fontSize: "12px" }}>KAIHO合同会社</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "10px", width: "100px", fontSize: "12px", fontWeight: "800" }}>代表者氏名</p>
                        <p style={{ fontSize: "12px" }}>井上 真登</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "10px", width: "100px", fontSize: "12px", fontWeight: "800" }}>本社所在地</p>
                        <p style={{ fontSize: "12px" }}>大阪府八尾市山城町<br />5丁目2番30号</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "10px", width: "100px", fontSize: "12px", fontWeight: "800" }}>資本金</p>
                        <p style={{ fontSize: "12px" }}>10万円</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "10px", width: "100px", fontSize: "12px", fontWeight: "800" }}>事業内容</p>
                        <p style={{ fontSize: "12px" }}>英語コーチング<br />コミュニティ運営</p>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#DDDDDD"}}>
                        <p style={{ marginRight: "10px", width: "100px", fontSize: "12px", fontWeight: "800" }}>お問い合せ</p>
                        <p style={{ fontSize: "12px" }}>english-coach@kaiho.earth</p>
                    </div>
                </div>
            </div>
        </>
    );
};