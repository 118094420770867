import React, { useEffect, useState } from 'react';

import Community from "./Community";
import CommunityMobile from './CommunityMobile';
import EnglishCoachDetail from "./EnglishCoachDetail";
import Session from "./Session";
import SessionMobile from './SessionMobile';
import WhatIsCoaching from "./WhatIsCoaching";
import WhatIsCoachingMobile from './WhatIsCoachingMobile';
import CommunitySmartphone from './CommunitySmartphone';
import EnglishCoachDetailSmartphone from './EnglishCoachDetailSmartphone';
import WhatIsCoachingSmartphone from './WhatIsCoachingSmartphone';
import SessionSmartphone from './SessionSmartphone';

export default function EnglishCoach () {
    const [isMobile, setIsMobile] = useState(false);
    const [isNarrow, setIsNarrow] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 955);
            setIsNarrow(window.innerWidth <= 650);
        };


        const handleInitialResize = () => {
            handleResize();
            window.removeEventListener('resize', handleInitialResize);
        };

        handleInitialResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div style={{ width: "100%", maxWidth: "1440px", paddingTop: "100px", paddingBottom: "100px" }}>
                {/* EnglishCoachDetail */}
                { isNarrow ? <EnglishCoachDetailSmartphone /> : <EnglishCoachDetail /> }
                {/* そもそもコーチングとは？ */}
                { isMobile ? ( isNarrow ? <WhatIsCoachingSmartphone /> : <WhatIsCoachingMobile /> ) : <WhatIsCoaching /> }
                {/* セッションの流れ */}
                { isMobile ? ( isNarrow ? <SessionSmartphone /> : <SessionMobile /> ) : <Session /> }
                {/* コミュニティ */}
                { isMobile ? ( isNarrow ? <CommunitySmartphone /> : <CommunityMobile /> ) : <Community /> }
            </div>
        </>
    );
};