import VoiceImage from "../../../assets/VOICE.png";
import Voice1Image from "../../../assets/voice1.png";
import Voice2Image from "../../../assets/voice2.png";
import Voice3Image from "../../../assets/voice3.png";

export default function Voice () {
    return (
        <div style={{ maxWidth: "1440px" }}>
            <div style={{ width: "100%", maxWidth: "1440px", paddingTop: "80px", paddingBottom: "80px"}}>
                <div>
                    <div style={{ display: "flex", width: "100%", maxWidth: "1440px", justifyContent: "center" }}>
                        <img src={VoiceImage} alt="" />
                    </div>
                    <p 
                        style={{
                            marginTop: "0px",
                            marginBottom: "50px",
                            width: "100%",
                            maxWidth: "1440px",
                            textAlign: "center",
                            fontSize: "34px",
                            fontFamily: "游ゴシック体",
                            fontWeight: "bold",
                        }}
                    >
                        お客様の声
                    </p>
                    {/* お客様の声の下にアンダーライン追加 */}
                </div>
                {/* お客様の声Yさん */}
                <div style={{ marginBottom: "60px", width: "full", maxWidth: "1440px" }}>
                    <div
                        style={{
                            margin: "auto",
                            display: "flex",
                            width: "full",
                            maxWidth: "1300px",
                            paddingTop: "30px",
                            paddingBottom: "30px",
                            backgroundColor: "#7BB6E3",
                            paddingLeft: "50px",
                            alignItems: "center",
                            borderRadius: "80px"
                        }}
                    >
                        <div style={{ marginRight: "80px", width: "251px", height: "251px" }}>
                            <img src={Voice1Image} alt="" style={{ width: "full" }} />
                        </div>
                        <p style={{ fontSize: "16px", color: "#FFFFFF", lineHeight: "1.75rem", verticalAlign: "middle" }}>
                            <span style={{ fontWeight: "800", lineHeight: "2.5rem" }}>Yさん / 20代男性・外資系企業</span>
                            <br />
                            まさとさんのコーチングを受けて、夢が叶いました。 
                            <br />
                            受講前と受講後の状態は、モチベーション・考え方・行動・目標など全てにおいて全く別人になったかのように
                            <br />
                            成長したと実感しています。コーチングは、目標を明確にします。
                            <br />
                            それを達成するために何をすべきかを自分の意見を尊重しつつ、軌道を目標達成に導いてくれます。
                            <br />
                            また、全てを肯定してくれるので行動するのがとても楽しくなり毎日充実感が味わえます。
                            <br />
                            このように人生をより良いものにするためにコーチングは必要だと身に染みて感じました。
                        </p>
                    </div>
                </div>
                {/* お客様の声Tさん */}
                <div style={{ marginBottom: "60px", width: "full", maxWidth: "1440px" }}>
                    <div
                        style={{
                            margin: "auto",
                            display: "flex",
                            width: "full",
                            maxWidth: "1300px",
                            paddingTop: "30px",
                            paddingBottom: "30px",
                            backgroundColor: "#FFFFFF",
                            paddingLeft: "50px",
                            alignItems: "center",
                            borderRadius: "80px",
                            borderWidth: "2px",
                            borderColor: "#7BB6E3",
                            borderStyle: "solid",
                        }}
                    >
                        <div style={{ marginRight: "80px", width: "251px", height: "251px" }}>
                            <img src={Voice2Image} alt="" style={{ width: "full" }} />
                        </div>
                        <p style={{ fontSize: "16px", color: "#000000", lineHeight: "1.75rem", verticalAlign: "middle" }}>
                            <span style={{ fontWeight: "800", lineHeight: "2.5rem" }}>Tさん / 30代男性・エンジニア</span>
                            <br />
                            まさとさんのコーチングを受けようと思った理由は、挑戦し続けているまさとさんのようになりたいと思ったからです。 
                            <br />
                            裏表がなく、第一印象もとてもいい人でした。「お金のため」ではなく、「心のそこからクライアントの役に立ちたい」
                            <br />
                            と思ってコーチングしているのが行動からも感じました。
                            <br />
                            また、受講前と後では「なぜ英語を勉強したいか？」がクリアになりました。
                            <br />
                            そして、「やらなきゃ」から「やりたい」になり、勉強が楽しくなりました。
                            <br />
                            迷った時、不安になった時に「まさとさんに相談すればどうにかなる！」そんな風にも思うようになりました。（笑）
                        </p>
                    </div>
                </div>
                {/* お客様の声Hさん */}
                <div style={{ marginBottom: "60px", width: "full", maxWidth: "1440px" }}>
                    <div
                        style={{
                            margin: "auto",
                            display: "flex",
                            width: "full",
                            maxWidth: "1300px",
                            paddingTop: "30px",
                            paddingBottom: "30px",
                            backgroundColor: "#7BB6E3",
                            paddingLeft: "50px",
                            alignItems: "center",
                            borderRadius: "80px"
                        }}
                    >
                        <div style={{ marginRight: "80px", width: "251px", height: "251px" }}>
                            <img src={Voice3Image} alt="" style={{ width: "full" }} />
                        </div>
                        <p style={{ fontSize: "16px", color: "#FFFFFF", lineHeight: "1.75rem", verticalAlign: "middle" }}>
                            <span style={{ fontWeight: "800", lineHeight: "2.5rem" }}>Hさん / 20代女性・大学生</span>
                            <br />
                            自分の将来の夢について話を親身になって聞いてくれ、サポートをしていただけると感じたからです。
                            <br />
                            コーチングを受ける前までは学習習慣がほとんどなかったですが、コーチと一緒に勉強のやり方や学習内容などを
                            <br />
                            決める学習習慣がつきました。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};